
import RoundAvatar from '@/components/common/img/RoundAvatar.vue';
import ReviewUser from '@/components/review/ReviewUser.vue';
import ReviewStars from '@/components/review/ReviewStars.vue';

export default {
  name: 'ReviewHeader',
  components: { RoundAvatar, ReviewUser, ReviewStars },
  props: {
    user: {
      type: Object,
      default: () => ({ profile: null, name: null }),
    },
    userChildrenAge: { type: Number, default: 0 },
    createdAt: { type: String, default: null },
    rating: { type: Number, default: 0 },
  },
};
