
export default {
  name: 'ReviewStars',
  props: {
    rating: { type: Number, required: true },
    starSize: { type: Number, default: 14 },
    starGap: { type: Number, default: 0 },
    hasToCeil: { type: Boolean, default: false }, // 값 올림 여부
  },
  computed: {
    starStyle: ({ starSize }) => ({
      width: `${starSize / 2}px`,
      height: `${starSize}px`,
      fontSize: `${starSize}px`,
    }),
    ratingNumber: ({ hasToCeil, rating }) =>
      hasToCeil ? Math.ceil(rating) : rating,
  },
};
