
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';

export default {
  name: 'ReviewUser',
  props: {
    userName: { type: String, required: true },
    userChildrenAge: { type: Number, required: true },
    createdAt: { type: String, required: true },
  },
  computed: {
    // 최대 5자까지 길이만 남기고 첫글자 제외 * 처리
    userNameMasked: ({ userName }) => {
      if (!userName) return '-';
      const maskedName = userName.split('').slice(0, 5);
      return maskedName[0] + Array(maskedName.length).join('*');
    },
    childAgeString: ({ userChildrenAge }) =>
      !userChildrenAge ? '-' : `${userChildrenAge}세`,
    createdAtString: ({ createdAt }) =>
      createdAt
        ? makeDateTextFormatKo(new Date(createdAt), 'yyyy. M. d. aa h:mm:ss')
        : '-',
  },
};
