import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=ebcc7d18&scoped=true"
import script from "./Review.vue?vue&type=script&lang=js"
export * from "./Review.vue?vue&type=script&lang=js"
import style0 from "./Review.vue?vue&type=style&index=0&id=ebcc7d18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebcc7d18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewHeader: require('/codebuild/output/src2597364184/src/components/review/ReviewHeader.vue').default,ReviewContents: require('/codebuild/output/src2597364184/src/components/review/ReviewContents.vue').default})
